import { Link } from "gatsby";
import React from "react";
import Head from "../components/head/Head";
import Layout from "../components/layout/Layout";


// markup
const NotFoundPage = () => {
  return (
    <div className="page__body">
      <Head title="Fehler" />
      <Layout>
        <main className="simple-page">
          <h1>Upps...Du hast einen falschen Link angegeben. Gehe zur <Link className="link--underline" to="/">Startseite</Link></h1>
        </main>
      </Layout>
    </div>
  )
}

export default NotFoundPage
